import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Button, Card, Stack } from "react-bootstrap";
import DatePickers from "react-datepicker";

import LineChart from "./lineChart";
import Header from "../common/Header";
import {
  MdCases,
  MdConnectedTv,
  MdCurrencyPound,
  MdFilterListAlt,
  MdGroup,
  MdSupervisedUserCircle,
} from "react-icons/md";
import {
  handleGetTotalNewUsers,
  handleGetNewUsersOnTrial,
  handleGetNewUsersOnPaid,
  handleGetNewUsersAsStudents,
  handleGetTotalRegisteredUsers,
  handleGetRevenue,
  handleGetCustomerChurnRate,
  handleGetActiveUsers,
  handleGetUserWithConnectedAccounts,
  handleGetActiveConnectedAccounts,
} from "../../services/dashboard.service";
import { CiCircleInfo } from "react-icons/ci";
import moment from "moment";
import AppTooltip from "../../components/AppTooltip";

export default function Dashboard() {
  const [totalNewUsersData, setTotalNewUsersData] = useState({
    totalNewUsers: 0,
  });
  const [newUsersOnTrialData, setNewUsersOnTrialData] = useState({
    newUsersOnTrial: 0,
  });
  const [newUsersOnPaidData, setNewUsersOnPaidData] = useState({
    newUsersOnPaid: 0,
  });

  const [newUsersAsStudentData, setNewUsersAsStudentData] = useState({
    newUsersAsStudents: 0,
  });

  const [totalRegisteredUsersData, setTotalRegisteredUsersData] = useState({
    totalRegisteredUsers: 0,
  });
  const [activeUserData, setActiveUserData] = useState({
    activeUsers: 0,
  });

  const [totalRevenueData, setTotalRevenueData] = useState({
    revenue: 0,
  });

  const [getCustomerChurnRateData, setCustomerChurnRateData] = useState({
    customerChurnRate: 0,
  });

  const [getActiveConnectedAccountsData, setActiveConnectedAccountsData] =
    useState({
      activeConnectedAccounts: 0,
    });

  const [getUsersWithConnectedAccountsData, setUsersWithConnectedAccountsData] =
    useState({
      usersWithConnectedAccounts: 0,
    });

  const [revenueDate, setRevenueDate] = useState(new Date());

  const currentDate = moment();
  const revenueDateMoment = moment(revenueDate);

  const isCurrentMonth =
    currentDate.isSame(revenueDateMoment, "month") &&
    currentDate.isSame(revenueDateMoment, "year");

  const ref = useRef();

  useEffect(() => {
    // fetch data from api
    // setTotalNewUsersData(data)

    handleGetTotalNewUsers().then((response) => {
      setTotalNewUsersData(response.data);
    });

    handleGetNewUsersOnTrial().then((response) => {
      setNewUsersOnTrialData(response.data);
    });

    handleGetNewUsersOnPaid().then((response) => {
      setNewUsersOnPaidData(response.data);
    });

    handleGetNewUsersAsStudents().then((response) => {
      setNewUsersAsStudentData(response.data);
    });
    handleGetActiveUsers().then((response) => {
      setActiveUserData(response.data);
    });

    handleGetTotalRegisteredUsers().then((response) => {
      setTotalRegisteredUsersData(response.data);
    });

    handleGetRevenue({
      date: moment(revenueDate).format("YYYY-MM-DD"),
    }).then((response) => {
      setTotalRevenueData(response.data);
    });

    handleGetCustomerChurnRate().then((response) => {
      setCustomerChurnRateData(response.data);
    });

    handleGetUserWithConnectedAccounts().then((response) => {
      setUsersWithConnectedAccountsData(response.data);
    });

    handleGetActiveConnectedAccounts().then((response) => {
      setActiveConnectedAccountsData(response.data);
    });
  }, []);
  return (
    <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <Header title="Dashboard" showAddButton={false} />

      <div className="flex-grow-1  pageContent pb-3">
        <Row className="g-4 mb-4">
          <Col key={"id"} sm={6} lg={4} xxl={3}>
            <Card className="rounded-3 h-100 bg-opacity-25 border-0 shadow-sm">
              <Card.Body>
                <Stack direction="horizontal" gap={3}>
                  <div className="total_new_users rounded-pill custom-width-38 custom-height-38 d-flex">
                    <span className="m-auto">
                      <MdGroup size={16} />
                    </span>
                  </div>

                  <div className="flex-grow-1">
                    <Card.Title className="fs-14 fw-normal ">
                      Total New Users
                    </Card.Title>
                    <Card.Subtitle className="fw-semibold fs-20">
                      {totalNewUsersData.totalNewUsers}
                    </Card.Subtitle>
                  </div>
                </Stack>
              </Card.Body>
            </Card>
          </Col>

          <Col key={"id"} sm={6} lg={4} xxl={3}>
            <Card className="rounded-3 h-100 bg-opacity-25 border-0 shadow-sm">
              <Card.Body>
                <Stack direction="horizontal" gap={3}>
                  <div className="new_users_trial rounded-pill custom-width-38 custom-height-38 d-flex">
                    <span className="m-auto">
                      <MdGroup size={16} />
                    </span>
                  </div>

                  <div className="flex-grow-1">
                    <Card.Title className="fs-14 fw-normal ">
                      <Stack direction="horizontal" gap={2}>
                        <span>New Users</span>
                        <div className="fs-14 me-auto on_trail">On Trial</div>
                      </Stack>
                    </Card.Title>
                    <Card.Subtitle className="fw-semibold fs-20">
                      {newUsersOnTrialData.newUsersOnTrial}
                    </Card.Subtitle>
                  </div>
                </Stack>
              </Card.Body>
            </Card>
          </Col>

          <Col key={"id"} sm={6} lg={4} xxl={3}>
            <Card className="rounded-3 h-100 bg-opacity-25 border-0 shadow-sm">
              <Card.Body>
                <Stack direction="horizontal" gap={3}>
                  <div className="new_users_paid rounded-pill custom-width-38 custom-height-38 d-flex">
                    <span className="m-auto">
                      <MdGroup size={16} />
                    </span>
                  </div>

                  <div className="flex-grow-1">
                    <Card.Title className="fs-14 fw-normal ">
                      <Stack direction="horizontal" gap={2}>
                        <span>New Users</span>
                        <div className="fs-14 me-auto paid">Paid</div>
                      </Stack>
                    </Card.Title>
                    <Card.Subtitle className="fw-semibold fs-20">
                      {newUsersOnPaidData.newUsersOnPaid}
                    </Card.Subtitle>
                  </div>
                </Stack>
              </Card.Body>
            </Card>
          </Col>

          <Col key={"id"} sm={6} lg={4} xxl={3}>
            <Card className="rounded-3 h-100 bg-opacity-25 border-0 shadow-sm">
              <Card.Body>
                <Stack direction="horizontal" gap={3}>
                  <div className="new_users_trial rounded-pill custom-width-38 custom-height-38 d-flex">
                    <span className="m-auto">
                      <MdGroup size={16} />
                    </span>
                  </div>

                  <div className="flex-grow-1">
                    <Card.Title className="fs-14 fw-normal ">
                      <Stack direction="horizontal" gap={2}>
                        <span>New Users</span>
                        <div className="fs-14 me-auto free">Student</div>
                      </Stack>
                    </Card.Title>
                    <Card.Subtitle className="fw-semibold fs-20">
                      {newUsersAsStudentData.newUsersAsStudents}
                    </Card.Subtitle>
                  </div>
                </Stack>
              </Card.Body>
            </Card>
          </Col>

          <Col key={"id"} sm={6} lg={4} xxl={3}>
            <Card className="rounded-3 h-100 bg-opacity-25 border-0 shadow-sm">
              <Card.Body>
                <Stack direction="horizontal" gap={3}>
                  <div className="registered_users rounded-pill custom-width-38 custom-height-38 d-flex">
                    <span className="m-auto">
                      <MdGroup size={16} />
                    </span>
                  </div>

                  <div className="flex-grow-1">
                    <Card.Title className="fs-14 fw-normal ">
                      <Stack direction="horizontal" gap={2}>
                        <span>Total Registered Users</span>
                        <AppTooltip
                          title="Total registered users in this system"
                          placement="top"
                        >
                          <Button
                            type="button"
                            variant="link"
                            className="border-0 p-0 lh-1"
                          >
                            <CiCircleInfo size={16} />
                          </Button>
                        </AppTooltip>
                      </Stack>
                    </Card.Title>
                    <Card.Subtitle className="fw-semibold fs-20">
                      {totalRegisteredUsersData.totalRegisteredUsers}
                    </Card.Subtitle>
                  </div>
                </Stack>
              </Card.Body>
            </Card>
          </Col>

          <Col key={"id"} sm={6} lg={4} xxl={3}>
            <Card className="rounded-3 h-100 bg-opacity-25 border-0 shadow-sm">
              <Card.Body>
                <Stack direction="horizontal" gap={3}>
                  <div className="new_users_trial rounded-pill custom-width-38 custom-height-38 d-flex">
                    <span className="m-auto">
                      <MdGroup size={16} />
                    </span>
                  </div>

                  <div className="flex-grow-1">
                    <Card.Title className="fs-14 fw-normal ">
                      <Stack direction="horizontal" gap={2}>
                        <span>Active Users</span>
                        <AppTooltip
                          title="Active users in last 7 days"
                          placement="top"
                        >
                          <Button
                            type="button"
                            variant="link"
                            className="border-0 p-0 lh-1"
                          >
                            <CiCircleInfo size={16} />
                          </Button>
                        </AppTooltip>
                      </Stack>
                    </Card.Title>
                    <Card.Subtitle className="fw-semibold fs-20">
                      {activeUserData.activeUsers}
                    </Card.Subtitle>
                  </div>
                </Stack>
              </Card.Body>
            </Card>
          </Col>

          <Col key={"id"} sm={6} lg={4} xxl={3}>
            <Card className="rounded-3 h-100 bg-opacity-25 border-0 shadow-sm">
              <Card.Body>
                <Stack direction="horizontal" gap={3}>
                  <div className="revenue rounded-pill custom-width-38 custom-height-38 d-flex">
                    <span className="m-auto">
                      <MdCurrencyPound size={16} />
                    </span>
                  </div>

                  <div className="flex-grow-1">
                    <Card.Title className="fs-14 fw-normal ">
                      <Stack direction="horizontal" gap={2}>
                        <span>Revenue</span>
                        <div className="fs-14 me-auto current_month-pack">
                          {isCurrentMonth
                            ? " Current Month"
                            : moment(revenueDate).format("MM/YYYY")}
                        </div>
                        <div className="d-flex dashboard-revenue">
                          <DatePickers
                            ref={ref}
                            className="form-control d-none h-0"
                            selected={revenueDate}
                            onChange={(date) => {
                              setRevenueDate(date);
                              handleGetRevenue({
                                date: moment(date).format("YYYY-MM-DD"),
                              }).then((response) => {
                                setTotalRevenueData(response.data);
                              });
                            }}
                            dateFormat="MM/yyyy"
                            maxDate={new Date()}
                            // excludeDates={[new Date("2024-05-01"), new Date("2024-06-01")]}
                            showMonthYearPicker
                          />
                          <Button
                            style={{
                              zIndex: 1,
                            }}
                            type="button"
                            variant="link"
                            className="border-0 p-0 lh-1"
                            onClick={() => {
                              ref.current.setOpen(true);
                            }}
                          >
                            <MdFilterListAlt size={16} />
                          </Button>
                        </div>
                      </Stack>
                    </Card.Title>
                    <Card.Subtitle className="fw-semibold fs-20">
                      £ {totalRevenueData.revenue}
                    </Card.Subtitle>
                  </div>
                </Stack>
              </Card.Body>
            </Card>
          </Col>

          <Col key={"id"} sm={6} lg={4} xxl={3}>
            <Card className="rounded-3 h-100 bg-opacity-25 border-0 shadow-sm">
              <Card.Body>
                <Stack direction="horizontal" gap={3}>
                  <div className="customer_churn_rate rounded-pill custom-width-38 custom-height-38 d-flex">
                    <span className="m-auto">
                      <MdCurrencyPound size={16} />
                    </span>
                  </div>

                  <div className="flex-grow-1">
                    <Card.Title className="fs-14 fw-normal ">
                      <Stack direction="horizontal" gap={2}>
                        <span>Customer Churn Rate</span>
                      </Stack>
                    </Card.Title>
                    <Card.Subtitle className="fw-semibold fs-20">
                      {getCustomerChurnRateData.customerChurnRate}%
                    </Card.Subtitle>
                  </div>
                </Stack>
              </Card.Body>
            </Card>
          </Col>
          <Col key={"id"} sm={6} lg={4} xxl={3}>
            <Card className="rounded-3 h-100 bg-opacity-25 border-0 shadow-sm">
              <Card.Body>
                <Stack direction="horizontal" gap={3}>
                  <div className="customer_churn_rate rounded-pill custom-width-38 custom-height-38 d-flex">
                    <span className="m-auto">
                      <MdSupervisedUserCircle size={16} />
                    </span>
                  </div>

                  <div className="flex-grow-1">
                    <Card.Title className="fs-14 fw-normal ">
                      <Stack direction="horizontal" gap={2}>
                        <span>Users with Connected Accounts</span>
                      </Stack>
                    </Card.Title>
                    <Card.Subtitle className="fw-semibold fs-20">
                      {
                        getUsersWithConnectedAccountsData.usersWithConnectedAccounts
                      }
                    </Card.Subtitle>
                  </div>
                </Stack>
              </Card.Body>
            </Card>
          </Col>
          <Col key={"id"} sm={6} lg={4} xxl={3}>
            <Card className="rounded-3 h-100 bg-opacity-25 border-0 shadow-sm">
              <Card.Body>
                <Stack direction="horizontal" gap={3}>
                  <div className="customer_churn_rate rounded-pill custom-width-38 custom-height-38 d-flex">
                    <span className="m-auto">
                      <MdConnectedTv size={16} />
                    </span>
                  </div>

                  <div className="flex-grow-1">
                    <Card.Title className="fs-14 fw-normal ">
                      <Stack direction="horizontal" gap={2}>
                        <span>Active Connected Accounts (Last 24 Hours)</span>
                      </Stack>
                    </Card.Title>
                    <Card.Subtitle className="fw-semibold fs-20">
                      {getActiveConnectedAccountsData.activeConnectedAccounts}
                    </Card.Subtitle>
                  </div>
                </Stack>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="g-4">
          <Col>
            <LineChart />
          </Col>
        </Row>
      </div>
    </div>
  );
}
